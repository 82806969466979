import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import {
  HowDoYouSay,
  WhatIsFormigio,
  WhatIsCommitment,
  WhatIsTracker,
  Examples,
  Introduction,
  Pricing,
  PageFooter,
  Hero
} from './components';
import {Helmet} from 'react-helmet';

const OverwhelmedView = ({ themeMode }) => {
  return (
    <div>
      <Helmet>
        <title>Formigio Planner - Effectively plan your day and focus so you can get things done, and stress less</title>
      </Helmet>
  
      <Hero themeMode={themeMode} />
      <SectionAlternate>
        <WhatIsFormigio />
      </SectionAlternate>
      <Section>
        <WhatIsCommitment />
      </Section>
      <SectionAlternate>
        <WhatIsTracker />
      </SectionAlternate>
      <Section>
        <Examples />
      </Section>
      <SectionAlternate>
        <HowDoYouSay />
      </SectionAlternate>
      <Section narrow>
        <Introduction />
      </Section>
      <SectionAlternate>
        <Pricing />
      </SectionAlternate>
      <Section>
        <PageFooter />
      </Section>
    </div>
  );
};

export default OverwhelmedView;
