import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Trademarked } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  fontWeight900: {
    fontWeight: 900
  },
  ctaSignup: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  sectionHeader: {
    [theme.breakpoints.down('xs')]: {
      '& .section-header__cta-item-wrapper': {
        width: '100%'
      }
    }
  }
}));

const PageFooter = () => {
  const classes = useStyles();

  return (
    <SectionHeader
      title={<>{'Start to use '}<Trademarked styles={{ fontSize: '1.7rem', top: 0, right: 2 }}>Formigio</Trademarked>{' today to remove the worry about missing details'}</>}
      align="center"
      titleProps={{
        variant: 'h3',
        color: 'primary',
        className: classes.fontWeight900,
      }}
      className={classes.sectionHeader}
      ctaGroup={[
        <Button variant="contained" color="secondary" size="large" component="a" className={classes.ctaSignup} href="https://app.formigio.com/register">
          Start for Free
        </Button>
      ]}
    />
  );
};

export default PageFooter;
