import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { navigate } from 'gatsby';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  subtitle: {
    marginTop: '2rem',
    fontWeight: 700
  }
}));

const WhatIsFormigio = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  return (
    <div className={className} {...rest}>
      <SectionHeader
        titleVariant="h2"
        title={<>How Do You Say <Trademarked styles={{ fontSize: '1.7rem', top: 4, right: 0 }}>Formigio</Trademarked>?</>}
        subtitle="It’s pronounced For-Me-Gee-Oh"
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
        subtitleProps={{
          variant: 'h5',
          component: 'p',
          align: 'center',
          color: 'secondary',
          className: classes.subtitle
        }}
      />
      <Typography align="center" component="p" variant="h6">
        It’s a play on the Portuguese word for Ant (the insect). Learn more about the name {' '}
        <Link
          color="primary"
          href="/about#history"
          onClick={event => {event.preventDefault(); navigate('/about#history')}}
          className={classes.listItemButton}
        >
          here
        </Link>
        .
      </Typography>
    </div>
  );
};

WhatIsFormigio.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhatIsFormigio;
