import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Trademarked } from 'components/atoms';

const useStyles = makeStyles((theme) => ({
  fontWeight900: {
    fontWeight: 900,
  },
  list: {
    listStyle: 'lower-alpha',
    paddingLeft: theme.spacing(2)
  },
  listItem: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

const list = [
  'Keep track of all the parts and pieces of an upcoming project.',
  'Remember the items needed for the school play that you volunteered to help with.',
  'Home or Car Maintenance',
  'Building a tree house for the kids'
];

const Examples = () => {
  const classes = useStyles();
  
  return (
    <div>
      <SectionHeader
        title={<>{'What are some examples of how '}<Trademarked styles={{ fontSize: '1.7rem', top: 4, right: 0 }}>Formigio</Trademarked>{' helps with stress?'}</>}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
      />
      <Typography component="ol" variant="h6" className={classes.list}>
        {list.map((item, idx) => (
          <li key={idx} className={classes.listItem}>{item}</li>
        ))}
      </Typography>
    </div>
  );
};

export default Examples;
