import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, styled } from '@material-ui/core/styles';
import {Button, Typography, ButtonBase} from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { SectionHeader } from 'components/molecules';
import { HeroShaped } from 'components/organisms';
import scollingImage from '../../../../assets/images/formigio-app-pages.png';
import Dialog from '@material-ui/core/Dialog';
import YouTubeEmbed from '../../../../components/molecules/YouTubeEmbed/YouTubeEmbed';
import {TypedText} from '../../../../components/molecules';

import learnImg from 'assets/images/learn.jpg';
import { Image, Trademarked } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
    minHeight: '3.75em'
  },
  leftSideContent: {
    '& .section-header__cta-container': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .section-header__cta-item-wrapper': {
          width: '100%',
          '&:last-child': {
            marginLeft: 0,
            marginTop: theme.spacing(1),
          },
          '& .MuiButtonBase-root': {
            width: '100%',
          },
        },
      },
    }
  },
  ctaVideoText: {
    margin: theme.spacing(1, 0)
  },
  ctaVideoPlayIcon: {
    position: 'absolute',
    fontSize: '3rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white'
  },
  heroShaped: {
    '& .hero-shaped__image': {
      backgroundColor: theme.palette.alternate.main,
    },
    [theme.breakpoints.down('sm')]: {
      '& .hero-shaped__image': {
        position: 'relative',
      },
      '& .hero-shaped__wrapper': {
        flexDirection: 'column',
      },
    },
  },
  imageAnimation: {
    background: `url("${scollingImage}")`,
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: '400px auto',
    animation: `$slideshow 50s linear infinite`,
    width: '600%',
    height: '600%',
    backgroundColor: theme.palette.alternate.dark,
    top: '-25%',
    left: '-100%',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '800px auto',
    }
  },
  imageAnimationDark: {
    background: `url("${scollingImage}")`,
  },
  '@keyframes slideshow': {
    '0%': {
      transform: 'rotate(-13deg) translateY(-25%)',
    },
    '100%': {
      transform: 'rotate(-13deg) translateY(-80%)',
    },
  },
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  width: 240,
  [theme.breakpoints.down('xs')]: {
    width: '100%'
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const Hero = ({ themeMode = 'light', className, ...rest }) => {
  const classes = useStyles();
  const [videoPlayer, setVideoPlayer] = useState(false);

  const title = (
    <TypedText
      variant="h3" component="div" color="primary" className={classes.fontWeight900}
      typedProps={{
      strings: [ "Want to be more organized?", "Feeling overwhelmed?", "Need something more than a todo list?" ],
      typeSpeed: 75,
      loop: false,
    }}/>
  );

  const subtitle = 'Stress is pressure that is caused by expectations that we put on ourselves, or that we accept from others.';

  const CTAWithThumbnail = () => (
    <div>
      <Typography
        variant="h6"
        color="textSecondary"
        component="p"
        className={classes.ctaVideoText}
      >
        Learn how <Trademarked>Formigio</Trademarked> can help
      </Typography>
      <ImageButton
        focusRipple
        onClick={() => setVideoPlayer(true)}
      >
        <Image src={learnImg} alt="Learn Formigio" />
        <PlayCircleOutlineIcon className={classes.ctaVideoPlayIcon} />
      </ImageButton>
    </div>
  );

  const leftSideContent = (
    <SectionHeader
      title={title}
      subtitle={subtitle}
      align="left"
      titleProps={{
        variant: 'h1',
        color: 'textPrimary',
      }}
      subtitleProps={{
        variant: 'h6',
        component: 'p'
      }}
      data-aos="fade-right"
      disableGutter
      className={classes.leftSideContent}
      ctaGroup={[
        <Button variant="contained" color="secondary" size="large" component="a" href="https://app.formigio.com/register">
          Start for Free
        </Button>,
        <CTAWithThumbnail />
      ]}
      ctaGroupDirection="column"
    />
  );
  return (
    <div className={className} {...rest}>
      <HeroShaped
        className={classes.heroShaped}
        leftSide={leftSideContent}
        rightSide={(
          <div
            className={clsx(
              classes.imageAnimation,
              themeMode === 'dark' ? classes.imageAnimationDark: '',
            )}
          />
        )}
      />
      <Dialog open={videoPlayer} maxWidth="md" onClose={() => setVideoPlayer(false)}>
        <YouTubeEmbed url="https://www.youtube.com/embed/eJZaOdcuS9A"/>
      </Dialog>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Theme mode
   */
  themeMode: PropTypes.string,
};

export default Hero;
