import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles((theme) => ({
  fontWeight900: {
    fontWeight: 900,
  },
  list: {
    listStyle: 'lower-alpha',
    paddingLeft: theme.spacing(2)
  },
  listItem: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  },
  subList: {
    listStyle: 'lower-roman',
    paddingLeft: theme.spacing(4)
  },
  subListItem: {
    marginTop: theme.spacing(1.5)
  }
}));

const list = [
  {
    content: 'Tracker documents, we also call them Accomplishment Trackers, are a powerful tool to keep track of more complicated initiatives, and dreams. They track the progress of something that is bigger than a simple commitments.'
  },
  {
    content: 'Trackers are broken into 4 main parts, to align with the Accomplishment Pattern.',
    children: [
      {
        content: 'Dream, Learn, Plan and Do (Progress)'
      },
      {
        content: 'In the dream section, we describe the main goal, and add success statements, further painting the picture.'
      },
      {
        content: 'In the Learn section, we hold things that you have learned that you may need.'
      },
      {
        content: 'In the Plan section you break down the bigger goal into smaller desired outcomes, or milestones, along the way.'
      },
      {
        content: 'In the Do or progress section you can record progress, and measurements along the way.'
      },
      {
        content: 'You can then commit to the milestones, and make progress as you keep those commitments.'
      }
    ]
  }
];

const WhatIsTracker = () => {
  const classes = useStyles();
  
  return (
    <div>
      <SectionHeader
        title="What is a Tracker?"
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
      />
      <Typography component="ol" variant="h6" className={classes.list}>
        {list.map(({ content, children }, idx) => (
          <li key={idx} className={classes.listItem}>
            {content}
            {children && children.length && (
              <Typography component="ol" variant="h6" className={classes.subList}>
                {children.map((subItem, index) => (
                  <li key={index} className={classes.subListItem}>{subItem.content}</li>
                ))}
              </Typography>
            )}
          </li>
        ))}
      </Typography>
    </div>
  );
};

export default WhatIsTracker;
