import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  SectionHeader } from 'components/molecules';
import { Link, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  marginBottom: {
    marginBottom: '1rem'
  }
}));

const WhyFormigio = ({ className, ...rest }) => {
  const classes = useStyles();
  const title = (<>Why{' '}<Trademarked styles={{ fontSize: '1.7rem', top: 4, right: 0 }}>Formigio</Trademarked>{'?'}</>);
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={title}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
      />
      
      <Typography component="p" variant="h6" className={classes.marginBottom}>
        Hey there, if you have landed here, and are watching this, then you probably clicked on a link for our overwhelmed campaign.<br/>
        The reason we are running this is because we think that Formigio can help, it can help people manage their stress.
        A lot of the time stress can come from expectations from ourselves and others.<br/>
        <br/>
        When original designed Formigio and had the idea for this application. I was busy, and I had a lot of stuff going on, and I just thought: "Is there something that I can do to build or to use, that would help me focus and understand what I needed to do for that day?"
        <br/>
        So, it really comes down to and the toolset that I came up with was a daily planner and a commitment list.
        <br/>
        The difference between a general planner that you write in or a todo list, you are generally writing things that you need to do, things that came to mind, or things that someone asked you for, etc.
        <br/>
        But you don't normally take the time to think. Do I have time to really do this today?
        <br/>
        It it's normally something that you just write down. You know you need to do it, so you write it down.
        <br/>
        Ultimately, at the end of the day though, you don't get to your todo list. Right? You only get to the first couple things that you wanted to get done.
        <br/>
        Then you feel overwhelmed, because now people are waiting on you. Maybe you have promised to do stuff that you don't have time to do.
        <br/>
        So, the basic idea of this planner, is that you put things in, in to these tracker documents, and then you can take those tracker documents and commit to do something about it, or use it to keep track of what you are trying to do.
        <br/>
        When you commit to do something, like if someone asks you, "hey, do you time to do this" "Can you help me with this?"
        <br/>
        You go back to your day, that you have already planned, and you can say "Actually I don't have time, but maybe I do have time tomorrow." Most of the time, people will be ok with that.
        <br/>
        Some of it a learned skill, that it's ok to do.  Some of it, is being able to track it.
        <br/>
        <br/>
        So, Formigio is really a utility that I built to help myself keep those commitments. and not be overwhelmed as much.
        <br/>
        So if you want to give it a try. Go ahead. Click the Try Now, Sign Up. You get a couple of free trackers.
        <br/>
        You can do as many commitments as you want. You can just use it as a todo list type thing for free forever. But if you need a little more tracker documents, and you need a little more structure, you can start to use it for free.
      </Typography>
    </div>
  );
};

WhyFormigio.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhyFormigio;
