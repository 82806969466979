import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid, Icon, Typography, useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Trademarked } from 'components/atoms';
import { pricings } from '../../../Pricing/data';
import { CardPricingStandard, Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  pricingContainer: {
    position: 'relative'
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
    paddingBottom: 0
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',
    }
  }
}));

const Pricing = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div>
      <SectionHeader
        title="Pricing"
        align="center"
        subtitle={<><Trademarked>Formigio</Trademarked>{' is free to try and use, with a limit of how many active trackers you have.'}</>}
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
        subtitleProps={{
          variant: 'h6',
          component: 'p'
        }}
      />
      <div className={classes.pricingContainer}>
        <div>
          <Section className={classes.sectionNoPaddingTop}>
            <Grid container spacing={isMd ? 4 : 2}>
              {pricings.map((item, index) => (
                <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                  <CardPricingStandard
                    variant="outlined"
                    withShadow={false}
                    title={item.title}
                    liftUp
                    subtitle={item.subtitle}
                    priceComponent={
                      <div>
                        <Typography
                          variant="h3"
                          component="span"
                        >
                          $
                          {item.price}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                          {item.priceSuffix}
                        </Typography>
                      </div>
                    }
                    features={item.features}
                    featureCheckComponent={
                      <Icon
                        fontIconClass="far fa-check-circle"
                        fontIconColor={theme.palette.primary.main}
                      />
                    }
                    cta={
                      <Button
                        color="primary"
                        variant={item.isHighlighted ? 'contained' : 'outlined'}
                        fullWidth
                        size="large"
                        href={item.href}
                      >
                        {item.label}
                      </Button>
                    }
                    className={classes.cardPricing}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
