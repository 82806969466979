import React, { useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import { ButtonBase, Dialog } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { SectionHeader, YouTubeEmbed } from 'components/molecules';
import { Image, Trademarked } from 'components/atoms';

import introductionImg from 'assets/images/introduction.jpg';

const useStyles = makeStyles((theme) => ({
  fontWeight900: {
    fontWeight: 900,
  },
  ctaVideoPlayIcon: {
    position: 'absolute',
    fontSize: '5rem',
    color: theme.palette.text.secondary,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const ImageButton = styled(ButtonBase)(() => ({
  position: 'relative',
  width: '100%',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const Introduction = () => {
  const [videoPlayer, setVideoPlayer] = useState(false);
  const classes = useStyles();
  
  return (
    <div>
      <SectionHeader
        title="Introduction"
        align="center"
        subtitle={<>{'Introducing our '}<Trademarked>Formigio</Trademarked>{' personal planner. A daily planner and goal tracking tool, that is easy to use and helpful.'}</>}
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
        subtitleProps={{
          variant: 'h6',
          component: 'p'
        }}
      />
      <ImageButton
        focusRipple
        onClick={() => setVideoPlayer(true)}
      >
        <Image src={introductionImg} alt="Introduce Formigio" />
        <PlayCircleOutlineIcon className={classes.ctaVideoPlayIcon} />
      </ImageButton>
      <Dialog open={videoPlayer} maxWidth="md" onClose={() => setVideoPlayer(false)}>
        <YouTubeEmbed url="https://www.youtube.com/embed/XiyD1NLBRZg"/>
      </Dialog>
    </div>
  );
};

export default Introduction;
