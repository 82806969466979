import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles((theme) => ({
  fontWeight900: {
    fontWeight: 900,
  },
  list: {
    listStyle: 'lower-alpha',
    paddingLeft: theme.spacing(2)
  },
  listItem: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

const list = [
  'Commitments are the life blood of getting things done. We don’t call them “to-dos” because that is too broad, and doesn’t mean anything. We know that when we are serious about keeping our commitments, that we will be more careful with what we commit to. Thus, reducing the stress, because we try not to over-commit.',
  'You may ask “Well if I don’t have a To-do list, then how do I remember something that I need to do later?” This is a great question. You can schedule commitments for future dates and times. This is great because you can build your day tomorrow… ahead of time.',
  'There are also things that you need to get done… but it involves multiple steps, or requires that things be done over time, and there is waiting involved. This is where a Tracker comes in.'
];

const WhatIsCommitment = () => {
  const classes = useStyles();
  
  return (
    <div>
      <SectionHeader
        title="What is a Commitment?"
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
      />
      <Typography component="ol" variant="h6" className={classes.list}>
        {list.map((item, idx) => (
          <li key={idx} className={classes.listItem}>{item}</li>
        ))}
      </Typography>
    </div>
  );
};

export default WhatIsCommitment;
