import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import trackerPage from '../../../../assets/images/tracker-page.png';
import homePage from '../../../../assets/images/home-page.png';
import {Trademarked} from '../../../../components/atoms';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  image: {
    boxShadow:
      '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
  lastGrid: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '40%',
    },
  },
  fontWeight900: {
    fontWeight: 900,
  }
}));

const WhatIsFormigio = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  return (
    <div className={className} {...rest}>
      <SectionHeader
        titleVariant="h2"
        title={(<>{"What is "}<Trademarked styles={{ fontSize: '1.7rem', top: 4, right: 0 }}>Formigio</Trademarked>{'?'}</>)}
        subtitle={<>
          <Typography>
            Our planner is a productivity tool that keeps track of what you are trying to do and what
            you have committed to do to reach defined goals and milestones.
            It’s a powerful tool that is very simple to use.
          </Typography>
          <Typography>
            We use it to keep our brains straight, and in doing so can relax a bit more. When you can effectively manage
            your time and, perhaps, more importantly manage expectations of those that we work or live with.
          </Typography>
          </>}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'primary',
          className: classes.fontWeight900,
        }}
        subtitleProps={{
          component: 'p'
        }}
    />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6}>
          <Grid container alignItems="center">
            <Image
              src={trackerPage}
              alt="Formigio Apps"
              className={classes.image}
              data-aos="fade-up"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            alignItems="center"
            className={classes.lastGrid}
          >
            <Image
              src={homePage}
              alt="Formigio Apps"
              className={classes.image}
              data-aos="fade-up"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

WhatIsFormigio.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhatIsFormigio;
